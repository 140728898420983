<template lang="pug">
.NestedDetail(v-if="renderComponent")
    #NestedDetail.detailTable(:class="{'empty': detailData.length === 0}" :style="{overflowX: dontUseScroll ? 'none' : 'auto'}")
      .NestedDetail-row.p-bottom-10(
        :style="gridTemplate",
        :id="`${moduleName}`"
        v-show="detailData.length > 0 || showDetailWithEmptyData")
        .NestedDetail-row-col.c-success.font-size-micro-small.font-weight-bold(v-if="selectableAll")
          CustomCheckbox(
          :id="`checkbox-nested-table-row`"
          :label="''"
          :checked="isAllSelected"
          @change="selectAll")

        .NestedDetail-row-col.c-success.font-size-micro-small.font-weight-bold(
          v-for="(field, fieldIndex) in detailFields"
          :id="`NestedDetailItem-header-${fieldIndex}`"
          :style="field.style || ''"
          :class="[field.name, { 'price': field.type === 'currency', 'u-textRight': field.type === 'quantity' || field.type === 'currency' || field.type === 'mono' || field.editable, 'fixed-col': field.type === 'fixed'}]",
          v-if="isFieldVisible(field.is_visible)") {{ field.short_title }}

      .NestedDetail-row.list-item(
        v-for="(row,rowIndex) in detailData",
        tabindex="-1",
        :id="`NestedDetailItem-${rowIndex}`"
        @click="configureRow(row)"
        :style="gridTemplate")
        .NestedDetail-row-col.checkbox(v-if="selectable")
          CustomCheckbox(
            :id="`checkbox-nested-table-select-all-${row.id}`"
            v-model="row.isChecked"
            label=""
            @change="selectItem(row)")
          
        .NestedDetail-row-col(
          :id="`${moduleName}-${headerRowIndex}-${rowIndex}-${fieldIndex}`"
          v-for="(field, fieldIndex) in detailFields"
          :style="field.style || ''"
          v-if="isFieldVisible(field.is_visible)",
          :class="[field.name, { 'price': field.type === 'currency', 'ff-mono u-textRight': field.type === 'currency' || field.type === 'mono' || field.type === 'quantity', 'fixed-col': field.type === 'fixed', 'u-textRight': field.editable , 'tooltip-relative': field.type === 'supplier'}]")
          template(v-if="field.type === 'select'")
            template(v-if="(field.editable === true && detailActions.indexOf('edit') > -1) && (rowData.status === 1 || (isActionButtonsVisible && rowData.status === 4))")
              .field
                select.txt.table-input(
                :tabindex="tabindex(rowIndex, field)",
                :id="`select-nested-table-row-${rowIndex}`"
                :name="`select-${rowIndex}`",
                v-model="form[rowIndex][field.name]",
                :disabled="field.disabled(row)"
                v-validate="generateValidationRules(field.validationRules, row)",
                @change="changeValue(field.name, row, rowIndex)"
                :class="{ 'is-danger': veeErrors.has(`select-${rowIndex}`) }")
                  option(
                  v-for="item in field.selectList.options(row[field.filterKey])"
                  :key="item[field.selectList.value]"
                  :value="item[field.selectList.value]"
                  :id="`option-nested-table-select-${rowIndex}`"
                  ) {{ item[field.selectList.label] }}
                Icon.icon-arrow-down(name="icon-arrow-down")
            template(v-else)
              template(v-if="field.dataField === 'unit' ")
                span(:id="`${moduleName}-${rowIndex}-${fieldIndex}-unitName`") {{ findUnit(row[field.name]).name }}
              template(v-else)
                span(:id="`${moduleName}-${rowIndex}-${fieldIndex}-${row[field.name]}`") {{ row[field.name] }}
          template(v-else-if="field.type === 'status'")
            span(:id="`${moduleName}-${rowIndex}-${fieldIndex}-statusText`") {{ field.getStatusText(row) }}

          template(v-else-if="field.type === 'currency'")
            template(v-if="field.editable === true && rowData.status === 1 && detailActions.indexOf('edit') > -1")
              currency-input.table-input.txt.ff-mono.u-textRight(
                :value="row[field.name]",
                :id="`${moduleName}-${rowIndex}-${fieldIndex}-currencyInput`"
                @blur.native="changeValue"
              )
            template(v-else)
              span(:id="`${moduleName}-${rowIndex}-${fieldIndex}-currencyValue`") {{ row[field.name] | formatCurrency6Digits }}

          template(v-else-if="field.type === 'mono'")
            span(style="display: inline-block; width: 100%;"
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-${row[field.name]}`") {{ row[field.name] }}
          template(v-else-if="field.type === 'with-badge'")
              | {{ row[field.name] }}
              span.row-badge(:class="[field.typeData(row).className]")
                | {{ field.typeData(row).badgeText }}
          template(v-else-if="field.type === 'percent'")
            span(style="display: inline-block; width: 100%;") {{ (row[field.name] !== null) ? '%' + row[field.name] : '-' }}
          template(v-else-if="field.type === 'date'")
            template(v-if="field.editable === true && rowData.status === 1 && detailActions.indexOf('edit') > -1")
              .field
                input.table-input.txt(
                type="date",
                :tabindex="field.tabIndex ? tabindex(rowIndex, field) : ''",
                :id="`date-input-table-row-${rowIndex}`"
                :name="`date-${rowIndex}`",
                v-model="form[rowIndex][field.name]",
                v-validate="`date_format:YYYY-MM-DD|date_between:${field.usableDate.from},${field.usableDate.to}`",
                :min="field.usableDate.from",
                @blur="changeValue(field.name, row, rowIndex, 'date')"
                :class="{ 'is-danger': veeErrors.has(`date-${rowIndex}`) }",
                format="YYYY-MM-DD")
                Icon.icon-arrow-down(name="icon-arrow-down")
            template(v-else)
              span(
                :id="`${moduleName}-${rowIndex}-${fieldIndex}-dateValue`"
              ) {{ new Date(row[field.name]).toLocaleDateString($i18n.locale) }}
          template(v-else-if="field.type === 'quantity'")
            template(v-if="(field.editable === true && detailActions.indexOf('edit') > -1) && (rowData.status === 1 || (isActionButtonsVisible && rowData.status === 4))")
              input.table-input.txt.no-spinners.quantity(
              :id="`quantity-input-table-row-${rowIndex}`"
              :name="`quantity-${rowIndex}`",
              :class="{ 'is-danger': veeErrors.has(`quantity-${rowIndex}`) }",
              v-validate="generateValidationRules(field.validationRules, row)",
              autocomplete="off",
              :tabindex="tabindex(rowIndex, field)",
              :disabled="field.disabledStatus && field.disabledStatus(row)",
              type="number",
              @change="changeValue(field.name, row, rowIndex)"
              @click="$event.target.select()"
              v-model.number="form[rowIndex][field.name]"
              step="any"
              ref="quantityInput")
            template(v-else)
              span(style="display: inline-block; width: 100%;") {{ (row[field.name] !== null) ? row[field.name] : '-' }}
          template(v-else-if="field.type === 'supplier'")
            span {{ getSupplierName(row[field.name]) }}
            Tooltip(
              v-if="row[field.name] && row[field.name].length > 10"
              white,
              right,
              :text="row[field.name]"
            )
          template(v-else-if="field.type === 'portal'")
            portalTarget(
              :name="field.name"
              :slot-props="detail = {row, rowIndex}"
            )
          template(v-else-if="field.type === 'spacer'")
            span
          template(v-else)
            | {{ row[field.name] || '-' }}
          template(v-if="field.withDescription && field.getTypeText(row.type).text")
            span(
              :class="field.getTypeText(row.type).class"
              :id="`${field.getTypeText(row.type).id ? field.getTypeText(row.type).id : 'custom'}-${moduleName}-${rowIndex}-${fieldIndex}`"
              ) {{ field.getTypeText(row.type).text }}
        ActionButton(
          :data="detailActions"
          :item="row"
          v-if="showDetailWithEmptyData || (!readOnly && !readOnlyForDetail && ((!rowData.approvalStatus || rowData.approvalStatus === 0 || (rowData.approvalStatus === 3 && isActionButtonsVisible)) && (rowData.status === 1 || (isActionButtonsVisible && rowData.status === 4))))"
          @onItemDelete="deleteDetail(row)"
          @onItemEdit="editDetail(row)"
          @onItemExport="exportDetail(row)"
        )

        .NestedDetail-row-col.actions(v-if="usePortalAction")
          portalTarget(
            name="actionPortal"
            :slot-props="rows = { rowData, row }"
          )
      div(v-if="showDetailWithEmptyData && !isDetailDataLoading")
        .NestedDetail-row.list-item(
          v-for="(row,rowIndex) in newRow"
          tabindex="-1"
          :id="`NestedDetailItem-${rowIndex}`"
          :style="gridTemplate")
          .NestedDetail-row-col(
            :id="`${moduleName}-${headerRowIndex}-${rowIndex}-${fieldIndex}`"
            v-for="(field, fieldIndex) in detailFields"
            :style="field.style || ''"
            @click="configureRow(row)"
            v-if="isFieldVisible(field.is_visible)",
            :class="[field.name, { 'price': field.type === 'currency', 'ff-mono u-textRight': field.type === 'currency' || field.type === 'mono' || field.type === 'quantity', 'fixed-col': field.type === 'fixed', 'u-textRight': field.editable}]")
            
            template(v-if="field.type === 'portal'")
              portalTarget(
                :name="field.name"
                :slot-props="detail = {row, rowIndex}"
              )
        Button(iconName="circle-add" v-if="activateNewRow" :justIcon="true" iconClass="circle-add" @click="addNewData")

    .NestedDetail-foot.m-top-20(v-if="!readOnly && !usePortalFooter")
      Button.m-right-20(
      v-if="rowData.status === 1 && canAddNewDetail"
      id="nested-detail-add-button",
      primary,
      inline,
      size="small"
      :disabled="disableNewDetailButton"
      iconName="icon-global-add",
      iconClass="icon-global-add",
      @click="addNewDetail")
        span  {{ addButtonText }}

      Button.m-right-20(
      :disabled="detailData.length === 0 || !isValidForm || isLoading || disableFinalizeButton || isLoadingFinalize"
      v-if="rowData.status === 1 && isFinalizeAction"
      id="nested-detail-finalize-button",
      primary,
      inline,
      size="small"
      iconName="icon-btn-success",
      iconClass="icon-left",
      @click="makeFinalize")
        span {{ finalizeButtonText }}

      template(v-if="extraButtonTexts.length > 0")

        Button.m-right-20(
        :disabled="detailData.length === 0"
        v-if="rowData.status === 2"
        id="nested-detail-extra-action-button-1",
        inline,
        primary,
        size="small"
        iconName="icon-btn-success",
        iconClass="icon-left",
        @click="detailExtraAction1")
          span  {{ extraButtonTexts[0] }}

        Button(
        :disabled="detailData.length === 0"
        v-if="rowData.status === 2 && extraButtonTexts.length === 2"
        id="nested-detail-extra-action-button-2",
        primary,
        inline,
        size="small"
        iconName="icon-btn-success",
        iconClass="icon-left",
        @click="detailExtraAction2")
          span {{ extraButtonTexts[1] }}

    .NestedDetail-foot.m-top-20(v-if="usePortalFooter")
      portalTarget(
        name="footerPortal"
        :slot-props="rowData"
      )

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'NestedDetail',
  props: {
    tableKey: {
      type: String,
      default: ''
    },
    showDetailWithEmptyData: {
      type: Boolean,
      default: false
    },
    dontUseScroll: {
      type: Boolean,
      default: false
    },
    isDetailDataLoading: {
      type: Boolean,
      default: false
    },
    isFinalizeAction: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    usePortalFooter: {
      type: Boolean,
      default: false
    },
    usePortalAction: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default () {
        return {}
      }
    },
    detailActions: {
      type: Array,
      default () {
        return []
      }
    },
    detailFields: {
      type: Array,
      default () {
        return []
      }
    },
    addButtonText: {
      type: String,
      default: ''
    },
    finalizeButtonText: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    extraButtonTexts: {
      type: Array,
      default () {
        return []
      }
    },
    openStatus: {
      type: Boolean,
      default: false
    },
    generateValidationRules: {
      type: Function
    },
    isLoadingFinalize: {
      type: Boolean,
      default: false
    },
    headerRowIndex: {
      type: Number
    },
    disableFinalizeButton: {
      type: Boolean,
      default: false
    },
    disableNewDetailButton: {
      type: Boolean,
      default: false
    },
    isActionButtonsVisible: {
      type: Boolean,
      default: false
    },
    readOnlyForDetail: {
      type: Boolean,
      default: false
    },
    canAddNewDetail: {
      type: Boolean,
      default: true
    },
    newRow: {
      type: Number,
      default: 0
    },
    activateNewRow: {
      type: Boolean,
      default: true
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    selectableAll: {
      type: String,
      default: ''
    },
    selectable: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      form: [],
      renderComponent: true
    }
  },

  computed: {
    ...mapGetters('Units', [
      'findUnit'
    ]),
    isAllSelected () {
      const selecteds = this.selectedRows.filter(s => s[`${this.tableKey}HeaderId`] === this.rowData.id)
      return this.detailData.length === selecteds.length
    },
    isValidForm () {
      return this.veeErrors.items.length === 0
    },
    gridTemplate () {
      const actionButtonWidth = 60
      const buttonWidth = actionButtonWidth * (this.detailActions.length !== 0 && this.rowData.status === 1 ? this.detailActions.length : 1)
      const detailLength = this.detailFields.filter(i => this.isFieldVisible(i.is_visible))
      return `grid-template-columns: repeat(${detailLength.length}, 1fr) ${buttonWidth}px;`
    },
    detailData () {
      return JSON.parse(JSON.stringify(this.rowData.detailList || []))
    },
    moduleName () {
      return `${this.$t('Page.header_' + this.$route.matched[0].meta.page)}Detail` || 'NestedTableDetail'
    }
  },
  watch: {
    detailData () {
      this.makeForms()
    },
    openStatus () {
      this.$nextTick(() => {
        if (this.$refs.quantityInput && this.openStatus) {
          this.$refs.quantityInput[0].select()
        }
      })
    }
  },
  beforeMount () {
    this.makeForms()
  },
  methods: {
    selectAll (val) {
      this.$emit('selectAllItems', this.detailData, val)
    },
    selectItem (item) {
      this.$emit('selectItem', item)
    },
    
    makeForms () {
      this.$validator.reset()
      const formItems = []
      this.detailData.forEach((detail) => {
        let items = {}
        this.detailFields.filter(field => field.editable).map(_ => {
          items[_.name] = _.type === 'date' ? moment(detail[_.name]).format('YYYY-MM-DD') : detail[_.name]
        })
        formItems.push(items)
      })
      this.form = [...formItems]
    },

    configureRow (row) {
      this.$emit('configureRow', row)
    },

    addNewData () {
      this.$emit('addNewData')
    },

    tabindex (rowIndex, field) {
      const fieldIndex = this.detailFields.filter(_ => _.editable).findIndex(f => f.name === field.name)
      return (2 * rowIndex) + (fieldIndex + 1)
    },

    showActionBar (item) {
      item.showActions = !item.showActions
      this.forceRerender()
    },

    closeActionBar (item) {
      item.showActions = false
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    addNewDetail () {
      this.$emit('addDetailAction', this.rowData)
    },
    makeFinalize () {
      this.$emit('finalizeAction', this.rowData)
    },
    editDetail (row) {
      this.$emit('editDetailAction', row)
    },
    exportDetail (row) {
      this.$emit('showDetailExportPopup', row)
    },
    deleteDetail (row) {
      this.$emit('deleteDetailAction', row)
    },
    changeValue (field, row, rowIndex, type = false) {
      const items = {
        ...row,
        ...this.form[rowIndex]
      }
      if (field === 'quantity' && !items.quantity) items.quantity = null
      let theSameControl = this.form[rowIndex][field] === row[field]
      if (this.veeErrors.items.length > 0 || theSameControl) return
      this.$emit('detailChangeAction', items)
    },
    detailExtraAction1 () {
      this.$emit('detailExtraAction', this.rowData)
    },
    detailExtraAction2 () {
      this.$emit('detailExtraActionSecound', this.rowData)
    },

    isFieldVisible (visible = false) {
      return typeof visible === 'function' ? visible(this.rowData) : visible
    },
    getSupplierName (item) {
      return item && item.length  > 10 ? item.substr(0, 10).concat('...') : item
    },
  }
}
</script>

<style scoped lang="scss">

@import "~@/stylesheet/config/mixin";

  .NestedDetail {
    width: 100%;
    padding: 20px;
    .detailTable {
      width: 100%;
      overflow-y: visible;
      cursor: ew-resize;
      margin-bottom: 60px;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      background-color: $color-white;
      &:not(.empty) {
        border-bottom: 1px solid $color-gray;
      }

      &.no-scroll {
        overflow-x: none!important;
      }
    }
    &-row {
      display: grid;
      grid-gap: 20px;
      padding-left: 20px;
      padding-right: 20px;
      grid-column: 1 / -1;
      min-width: min-content;
      &.list-item {
        height: $finger-size-big;
        align-items: center;
        font-size: $font-size-small;
        border-left: 1px solid $color-gray;
        border-right: 1px solid $color-gray;
        &:not(:last-child) {
          border-bottom: 1px solid $color-gray;
        }
        &:nth-child(2) {
          border-top: 1px solid $color-gray;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
      }
      &-col {
        min-width: 70px;
        font-weight: 500;
        position: relative;
        input {

         &.quantity {
           max-width: 70px;
           text-align: right;
           float: right;
         }
        }
        .field {
          position: relative;
          width: auto;
        }
        .icon-arrow-down {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 16px;
          z-index: 0;
          cursor: pointer;
        }
        select, input[type=date] {
          appearance: none;
          padding-right: 22px;
        }
        input[type=date]::-webkit-inner-spin-button,
        input[type=date]::-webkit-calendar-picker-indicator {
          background: transparent;
          bottom: 0;
          color: transparent;
          cursor: pointer;
          height: auto;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
          appearance: none;
          z-index: 10;
        }

        input[type=date]::-webkit-inner-spin-button {
          display: none;
        }

        input[type="date"]::-webkit-clear-button {
          display: none;
        }

        .table-input {
          min-height: 40px;
        }
        &.price {
          min-width: 125px;
          word-break: break-all;
        }
        &.ff-mono {
          font-weight: normal;
        }
        &.fixed-col {
          position: sticky;
          top: 0;
          left: 0;
          height: 100%;
          display: flex;
          align-items: center;
          z-index: 2;
          background-color: $color-white;
        }
        &.actions {
          display: flex;
          justify-content: flex-end;
        }
      }

      &:focus-within {
        border: 2px dashed $color-primary !important;
      }
    }
    &-foot {
      display: flex;
      justify-content: flex-end;
    }
  }
  :deep(.circle-add) {
    width: 20px !important;
    height: 20px !important;
    color: $color-success !important;
    &:hover {
      color: $color-success-dark;
      transition: $transition all;
    }
  }
</style>