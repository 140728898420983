<template lang="pug">
label(:for="id", :class="variants")
    input(type="checkbox" :name="name" :id="id" v-model="value" :disabled="isDisabled" @change="change").switch-input
</template>

<script>
export default {
  props: {
    name: { type: String, default: 'switch' },
    variant: { type: String },
    size: { type: String },
    active: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false}
  },
  data () {
    return {
      value: this.active
    }
  },
  computed: {
    variants () {
      return {
        switch: true,
        active: this.value,
        [`switch-${this.size}`]: this.size,
        [`switch-${this.variant}`]: this.variant
      }
    },
    id () {
      return this.name + this._uid
    }
  },
  watch: {
    active () {
      this.value = this.active
    }
  },
  methods: {
    change () {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>

  .switch {
    display: inline-block;
    width: 37px;
    height: 22px;
    background-color: $color-red;
    border: 1.7px solid $color-red;
    border-radius: $border-higher-radius;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .15s ease-in-out;
    cursor: pointer;
    &.passive {
      background: yellow;
    }

    &-input {
      opacity: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 3.5px;
      top: 0;
      border-radius: $border-circle-radius;
      background-color: map-get($theme, 'white');
      width: 30%;
      height: 11px;
      margin-top: 3.5px;
      z-index: 99;
      transition: all .15s ease-in-out;
    }

    &-sm {
      width: 40px;
      height: 22px;
    }

    &.passive {
      background-color: #CFD1D2 !important;
      border-color: #CFD1D2 !important;
    }

    &.active {
      background-color: $color-success;
      border-color: $color-success;

      @each $variant, $color in $theme {
        &.switch-#{$variant}{
          background-color: $color;
          border-color: $color;
        }
      }

      &::after {
        transform: translateX(170%);
      }
    }
  }
</style>
