<template lang="pug">

  .UserDrop(v-click-outside="onClickOutside")
    button.UserDrop-action(
    id="btn-user-dropdown"
    type="button"
    @click="drop = !drop"
    ) {{ shortName }}

    .UserDrop-menu(v-show="drop")
      .UserDrop-menu-row.info
        .user {{ Profile.user.firstname }} {{ Profile.user.surname }}
        .tenant {{ Profile.tenant.name }}
      .UserDrop-menu-row.settings
        button.UserDrop-menu-button(
        id="btn-user-dropdown-settings"
        type="button"
        @click="settings") {{ $t('Global.userMenu_Settings') }}
      .UserDrop-menu-row.logout
        Button.UserDrop-menu-button(
        id="btn-user-dropdown-logout"
        type="button"
        iconName="icon-logout"
        iconClass="icon-logout"
        @click="logOut")
          span {{ $t('Global.userMenu_Logout') }}
      .UserDrop-menu-row.version
        Icon(name="icon-global-info")
        span v{{ appVersion }}

</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'UserMenu',
  data () {
    return {
      drop: false
    }
  },

  computed: {
    ...mapGetters('Auth', [
      'Profile'
    ]),

    ...mapGetters('Global', [
      'appVersion'
    ]),

    shortName () {
      const firstname = this.isSupportUser ? 'S' : this.Profile.user.firstname.charAt(0)
      const surname = this.isSupportUser ? 'S' : this.Profile.user.surname.charAt(0)
      return `${firstname}${surname}`.toLocaleUpperCase()
    }

  },

  methods: {

    settings () {
      this.drop = false
      this.$router.push({
        name: 'SettingsUser'
      })
    },
    ...mapMutations('Global', [
      'RESET_SUSPEND'
    ]),

    async logOut () {
      this.RESET_SUSPEND()
      await this.$store.dispatch('Auth/Logout', null, { root: true })
      this.$router.push({
        name: 'Login'
      })
    },

    onClickOutside () {
      this.drop = false
    }
  }
}
</script>

<style lang="scss">

  .UserDrop {
    position: relative;
    display: flex;
    align-items: center;

    &-action {
      background-color: $color-seashell;
      border-radius: 50%;
      width: $finger-size-small;
      height: $finger-size-small;
      color: $color-success;
      border: 2px solid;
    }

    &-menu {
      user-select: none;
      position: absolute;
      right: 0;
      top: 100%;
      margin-top: 15px;
      width: 240px;
      background-color: white;
      box-shadow: 0 10px 20px rgba(black, .1);
      border: 1px solid $color-gray;
      white-space: nowrap;
      border-radius: $border-radius;
      z-index: $z-index-xxl;

      &::before,
      &::after {
        content: '';
        z-index: 1;
        position: absolute;
        right: $finger-size-small / 2;
        bottom: 100%;
        transform: translateX(50%);
        pointer-events: none;
        border: 8px solid;
        border-color: transparent transparent $color-ocean transparent;
      }

      &::before {
        margin-bottom: 1px;
        border-color: transparent transparent $color-gray transparent;
      }

      button {
        font-weight: $font-weight-normal;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        color: inherit;
        padding: 15px 20px;
      }

      &-row {
        &.info {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          padding: 15px 20px;
          background-color: $color-ocean;
          white-space: break-spaces;

          .user {
            color: $color-primary;
            font-weight: $font-weight-bold;
          }
          .tenant {
            font-size: $font-size-small;
          }
        }
        &.settings {
          button {
            &:hover {
              background-color: rgba(black, .05);
            }
          }
        }
        &.logout {
          border-top: 1px solid $color-gray;

          button {
            color: $color-warning;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            &:hover {
              background-color: rgba($color-warning, .1);
            }
          }
          svg {
            margin-left: auto;
          }
        }

        &.version {
          border-top: 1px solid $color-gray;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          svg {
            width: 16px;
            height: 16px;
            opacity: .6;
          }
          span {
            margin-left: 7px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .Language {
    margin-right: 20px;
  }
</style>
