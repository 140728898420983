<template lang="pug">
div(v-if="fields.length > 0 && renderComponent")
  .NestedTable
    .NestedTable-head(:style='gridTemplate')
      .NestedTable-head-col(
        v-for="(field, index) in fields"
        v-if="field.is_visible"
        :id="`Nested-table-header-${index}`"
        :class="[columnHeaderClassObject(field), { 'mono u-textRight': field.type === 'currency' }]"
        :style="field.style || ''"
        )
        template(v-if="field.titlePortalName")
          portalTarget(
            :name="field.titlePortalName"
          )
        template(v-else)
          span {{ field.short_title || '' }}
          Icon(
          v-if="field.withIcon",
          :class="field.iconClass",
          :name="field.iconName")
    .NestedTable-item(
      :id="`${moduleName}Table`"
      v-for="(row, rowIndex) in data",
      :class="[{ 'isOpened': row.showDetail }, {'disabledRow': row.disabledRow }]",
      )
      .NestedTable-row(
        :id="`${componentName}-nested-table-header-row-${rowIndex}`"
        :style='gridTemplate'
        :class="{ ['row-' + row.rowTextClass]: row.rowTextClass }"
      )

        .NestedTableHeaderLink(
          v-if="!row.dontShowSwitchIcon"
          :id="`btn-nested-table-open-header-${rowIndex}`"
          @click="showDetail(row)")
        .NestedTable-status-div
          StatusTooltip(
            :title="statusText"
            icon="icon-row-status"
            v-if="statusIcon && row.status === 1"
          )
        .NestedTable-row-col(
          v-for="(field, fieldIndex) in fields"
          :class="[field.name, { 'ff-mono u-textRight': field.type === 'currency' , 'tooltip-relative': field.type === 'supplier'}, { [row.rowTextClass]: row.rowTextClass }]"
          :style="field.style || ''",
          :id="`${moduleName}RowCol-${rowIndex}-${fieldIndex}`"
          v-if="field.is_visible")
          template(v-if="field.isEditable && field.isEditable(row)")
            template(v-if="field.type === 'dropdown'")
              .field
                select.txt.table-input(
                :tabindex="`${rowIndex*fieldIndex}`",
                :id="`select-nested-table-header-row-${rowIndex}`"
                :name="`select-header-${rowIndex}`",
                :disabled="field.disabledStatus(row)",
                v-model="headerForm[rowIndex][field.selectValue]"
                @change="changeFieldValue($event.target.value, field.selectValue, row)"
                v-validate="generateValidationRules(field.validationRules, row)",
                :class="{ 'is-danger': veeErrors.has(`select-header-${rowIndex}`) }")
                  option(
                  v-for="item in field.selectList.options"
                  :key="item[field.selectList.value]"
                  :value="item[field.selectList.value]"
                  :id="`option-nested-table-select-${rowIndex}`"
                  ) {{ item[field.selectList.label] }}
                Icon.icon-arrow-down(name="icon-arrow-down")

          template(v-else-if="field.type === 'currency'")
            span(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-currency`"
            ) {{ row[field.name] | formatCurrency3Digits }}
          template(v-else-if="field.type === 'status'")
            span(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-statusText`"
            ) {{ field.getStatusText(row) }}
          template(v-else-if="field.type === 'mono'")
            span.ff-mono.u-textRight(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-${row[field.name]}`" style="display: inline-block; width: 100%;"
            ) {{ row[field.name] }}
          template(v-else-if="field.type === 'portal'")
            portalTarget(
              :name="field.name"
              :slot-props="header = { row, rowIndex }"
            )
          template(v-else-if="field.type === 'phone'")
            span.ff-mono(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-phonr`"
            ) {{ row[field.name] | formatPhone }}
          template(v-else-if="field.type === 'with-badge'")
              | {{ row[field.name] }}
              span.row-badge(:class="[field.typeData(row).className]")
                span(
                  :id="`${moduleName}-${rowIndex}-${fieldIndex}-badgeText`"
                ) {{ field.typeData(row).badgeText }}
          template(v-else-if="field.type === 'percent'")
            span.ff-mono.u-textRight(style="display: inline-block; width: 100%;",
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-mono`") %{{ row[field.name] | percentFixed }}

          template(v-else-if="field.type === 'date'")
            span(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-dateValue`"
            ) {{ !row[field.name] ? '-' : new Date(row[field.name]).toLocaleDateString($i18n.locale) }}
          template(v-else-if="field.type === 'supplier'")
            span(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-tooltip`"
            ) {{ getSupplierName(row[field.name]) }}
            Tooltip(
              v-if="row[field.name] && row[field.name].length > 25" 
              white,
              right,
              :text="(row[field.name])"
            )

          template(v-else-if="field.type === 'spacer'")
            span
          template(v-else)
            span(
              :id="`${moduleName}-${rowIndex}-${fieldIndex}-${row[field.name]}`"
            ) {{ row[field.name] || '-' }}
        .NestedTable-row-col.action
          .body
            template(v-if="actions.indexOf('single-action-portal-start') > -1 ")
              portalTarget(
                name="single-action-portal-start"
                :slot-props="header = { row, rowIndex }"
              )

            Button(
              size="small"
              variant="icon c-success btn-switch"
              :justIcon="true"
              v-if="!row.dontShowSwitchIcon"
              @click="showDetail(row)",
              iconName="icon-arrow-down",
              iconClass="icon-arrow-down",
            )

            Button(
              size="small"
              variant="icon bgc-gray c-light"
              v-if="actions.indexOf('inspect') > -1 "
              :justIcon="true"
              :id="`btn-nested-table-inspect-header-${rowIndex}`"
              @click="inspectHeader(row)",
              iconName="icon-global-inspect",
              iconClass="icon-global-inspect"
            )
            div(v-if="useHeaderPortalAction")
              portalTarget(
                name="actionPortal"
                :slot-props="rows = { row }"
              )

            ActionButton(
              :data="actions"
              :item="row"
              v-if="!readOnly && !useHeaderPortalAction"
              :cannotBeViewed="row.docType === 2"
              :showExportOnly="row.status !== 1 && actions.indexOf('export') > -1"
              :customActionName = "customActionName"
              :customActionIconName="customActionIconName"
              :customActionIconClass="customActionIconClass"
              @onItemCustomAction="onItemCustomAction(row)"
              @onItemDelete="deleteHeader(row)"
              @onItemExport="showExportPopup(row)"
              @onItemEdit="editHeader(row)"
              @onItemOverview="showItem(row)"
              @onItemChangeActiveStatus="changeActiveStatus(row)"
            )
            
      nested-detail(
        v-show="row.showDetail",
        :tableKey="tableKey",
        :rowData="row",
        ref="nestedDetail",
        :isFinalizeAction="isFinalizeAction",
        :isLoading="isDetailLoading"
        :detailFields="detailFields",
        :addButtonText="addButtonText",
        :finalizeButtonText="finalizeButtonText",
        @addDetailAction="addNewDetail",
        @finalizeAction="makeFinalize"
        :readOnly="readOnly",
        :readOnlyForDetail="readOnlyForDetail"
        :canAddNewDetail="canAddNewDetail"
        :detailActions="detailActions",
        :newRow="newRow"
        :isDetailDataLoading="isDetailDataLoading"
        :activateNewRow="activateNewRow"
        :extraButtonTexts="extraButtonTexts",
        :selectedRows="selectedRows"
        :selectableAll="selectableAll"
        :selectable="selectable"
        :showDetailWithEmptyData="showDetailWithEmptyData"
        @detailExtraAction="detailExtraAction"
        @addNewData="addNewData"
        @detailExtraActionSecound="detailExtraActionSecound",
        @editDetailAction="editDetail",
        @deleteDetailAction="deleteDetail",
        @showDetailExportPopup="showDetailExportPopup"
        @detailChangeAction="detailChangeAction"
        @configureRow="configureRow"
        @selectAllItems="selectAllItems"
        @selectItem="selectItem"
        :usePortalFooter="usePortalFooter"
        :usePortalAction="usePortalAction"
        :dontUseScroll="dontUseScroll"
        :openStatus="row.showDetail"
        :generateValidationRules="generateValidationRules"
        :isLoadingFinalize="isLoadingFinalize"
        :headerRowIndex="rowIndex"
        :isActionButtonsVisible="isActionButtonsVisible"
        :disableNewDetailButton="disableNewDetailButton"
        :disableFinalizeButton="disableFinalizeButton(row)"
        )
  .NestedTable-footer.m-top-20(v-if="page")
    TableFoot(
      :page="page"
      @delegateOnChangePage="onChangePage"
      @delegateOnChangePageSize="onChangePageSize")
</template>

<script>
import StatusTooltip from '@/view/global/alert-status-tooltip'
import NestedDetail from './detail'
import TableFoot from '@/view/global/table/foot.vue'

export default {
  name: 'NestedTable',
  components: {
    StatusTooltip,
    NestedDetail,
    TableFoot
  },
  props: {
    value: '',
    tableKey: {
      type: String,
      default: ''
    },
    page: {
      type: Object,
      required: false
    },
    isDetailDataLoading: {
      type: Boolean,
      default: false
    },
    statusTooltipText: {
      type: String,
      default: ''
    },
    showDetailWithEmptyData: {
      type: Boolean,
      default: false
    },
    activateNewRow: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    dontUseScroll: {
      type: Boolean,
      default: false
    },
    detailFields: {
      type: Array,
      default () {
        return []
      }
    },
    usePortalFooter: {
      type: Boolean,
      default: false
    },
    usePortalAction: {
      type: Boolean,
      default: false
    },
    useHeaderPortalAction: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    isDetailLoading: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    readOnlyForDetail: {
      type: Boolean,
      default: false
    },
    canAddNewDetail: {
      type: Boolean,
      default: true
    },
    addButtonText: {
      type: String,
      default: ''
    },
    finalizeButtonText: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default () {
        return []
      }
    },
    detailActions: {
      type: Array,
      default () {
        return []
      }
    },
    customActionName: {
      type: String,
      default: ''
    },
    customActionIconName: {
      type: String,
      default: 'icon-navigation-transfer'
    },
    customActionIconClass: {
      type: String,
      default: 'icon-navigation-transfer'
    },

    extraButtonTexts: {
      type: Array,
      default () {
        return []
      }
    },
    newRow: {
      type: Number,
      default: 0
    },
    isFinalizeAction: {
      type: Boolean,
      default: true
    },
    statusIcon: {
      type: Boolean,
      default: true
    },
    isLoadingFinalize: {
      type: Boolean,
      default: false
    },
    componentName: {
      type: String,
      default: 'custom'
    },
    disableFinalizeButton: {
      type: Function,
      default: header => false
    },
    disableNewDetailButton: {
      type: Boolean,
      default: false
    },
    isActionButtonsVisible: {
      type: Boolean,
      default: false
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    selectableAll: {
      type: String,
      default: ''
    },
    selectable: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      headerForm: [],
      renderComponent: true,
      detailData: []
    }
  },
  computed: {
    statusText () {
      return !this.statusTooltipText
        ? this.$t('Despatches.HeaderNotSaved')
        : this.statusTooltipText
    },
    gridTemplate () {
      const actionButtonWidth = 40
      const buttonWidth = (this.actions.length + 1) * actionButtonWidth
      const fieldsLength = this.fields.filter(_ => _.is_visible && _.name !== 'status').length
      return `grid-template-columns: repeat(${fieldsLength}, 1fr) ${buttonWidth}px;`
    },
    columnHeaderClassObject () {
      return ({ name, type }) => ([
        name,
        {
          mono: (
            type === 'currency' ||
            type === 'mono' ||
            type === 'percent'
          )
        }
      ])
    },

    moduleName () {
      return this.$t('Page.header_' + this.$route.matched[0].meta.page).replace(/\s/g, '') || 'NestedTable'
    }
  },
  methods: {
  
    selectAllItems (detailData, isCheckedAll) {
      this.detailData = detailData
      detailData.forEach(i => {
        isCheckedAll ? i.isChecked = true : i.isChecked = false
      }) 
      this.$emit('selectAllItems', detailData, isCheckedAll)
    },
    selectItem (item) {
      this.$emit('selectItem', item)
    },

    generateValidationRules (validationRules, item) {
      return (validationRules) ? typeof validationRules === 'function' ? validationRules(item) : validationRules : false
    },

    getSupplierName (item) {
      return item.length > 25 ? item.substr(0, 25).concat('...') : item
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    async changeFieldValue (value, field, row) {
      this.$nextTick(() => {
        if (this.veeErrors.items.length > 0) return
        const newData = {
          ...row,
          [`${field}`]: value
        }
        this.$emit('changeRowData', newData)
      })
    },
    showActionBar (item) {
      item.showActions = !item.showActions
      this.forceRerender()
    },
    closeActionBar (item) {
      item.showActions = false
    },
    onItemCustomAction (item) {
      this.$emit('onItemCustomAction', item)
    },
    showDetail (item) {
      this.$emit('showDetailAction', item)
    },
    deleteHeader (item) {
      this.$emit('deleteHeaderAction', item)
    },
    editHeader (item) {
      this.$emit('editHeaderAction', item)
    },
    changeActiveStatus (item) {
      this.$emit('changeActivationStatusHeaderAction', item)
    },
    inspectHeader (item) {
      this.$emit('inspectHeaderAction', item)
    },
    addNewDetail (data) {
      this.$emit('addDetailAction', data)
    },
    makeFinalize (data) {
      this.$emit('finalizeAction', data)
    },
    editDetail (row) {
      this.$emit('editDetailAction', row)
    },
    showExportPopup (row) {
      this.$emit('showExportPopup', row)
    },
    showDetailExportPopup (row) {
      this.$emit('showDetailExportPopup', row)
    },
    showItem (row) {
      this.$emit('overviewHeaderAction', row)
    },
    deleteDetail (row) {
      this.$emit('deleteDetailAction', row)
    },
    detailExtraAction (data) {
      this.$emit('detailExtraAction', data)
    },
    detailExtraActionSecound (data) {
      this.$emit('detailExtraActionSecound', data)
    },
    addNewData() {
      this.$emit('addNewData')
    },
    detailChangeAction (data) {
      this.$emit('detailChangeAction', data)
    },
    configureRow (row) {
      this.$emit('configureRow', row)
    },
    onChangePage (page) {
      this.$emit('delegateOnChangePage', page)
    },
    onChangePageSize (pageSize) {
      this.$emit('delegateOnChangePageSize', pageSize)
    },
    makeForms () {
      if (!this.fields.some(field => field.isEditable)) return []
      const formItems = []
      this.data.forEach((row) => {
        let items = {}
        this.fields.filter(field => field.isEditable && field.isEditable(row)).map(_ => {
          if (_.type === 'dropdown') {
            items[_.selectValue] = row[_.selectValue]
          } else {
            items[_.name] = row[_.name]
          }
        })
        formItems.push(items)
      })
      this.headerForm = [...formItems]
      return formItems
    }
  },
  async beforeMount () {
    this.makeForms()
    this.$watch('data', async () => {
      this.makeForms()
      await this.$validator.reset()
    })
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

.NestedTable {
  &-item {
    height: $finger-size-big;
    width: 100%;
    border: 1px solid $color-gray;
    border-width: 1px 1px 0 1px;
    background: $color-white;
    &:nth-child(odd) {
      background-color: $color-snow;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-bottom: 1px solid $color-gray;
    }
    &:nth-child(2) {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    &.disabledRow {
      background-color: $color-light-gray;
    }
    &.isOpened {
      background-color: $color-white;
      border: 1px solid $color-light;
      height: 100%;
      .NestedTable-row {
        background-color: $color-ocean;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: 1px solid $color-gray;
      }
      .btn-switch {
        transform: rotate(180deg);
      }
    }
    &:hover {
      &:not(.isOpened) {
        background: $color-ocean;
        border:1px solid $color-light-blue;
      }
    }
  }
  &-head, &-row {
    height: $finger-size-big;
    width: 100%;
    display: grid;
    grid-gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    font-size: $font-size-small;
  }
  &-head {
    font-size: $font-size-micro;
    font-weight: bold;
    border: 1px solid $color-gray;
    border-bottom: unset !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: $color-lighter-blue;
    color: $color-success;

    &-col {
      min-width: 100%;
    }
  }
  &-row {
    position: relative;

    .NestedTable-status-div {
      position: absolute;
    }
    &-col {
      word-break: break-all;
      .field {
        position: relative;
        width: auto;
      }
      .icon-arrow-down {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 16px;
        z-index: 0;
        cursor: pointer;
      }
      .table-input {
        min-height: 40px;
      }
      select, input[type=date] {
        appearance: none;
        padding-right: 22px;
      }
      &.ff-mono {
        font-weight: normal;
      }
      &.action {
        justify-content: flex-end;
        .body {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

      }
    }
    &:hover {
      .action {
        display: flex;
      }
    }
  }
}

.row-TableViewPassiveItems {
  background-color: lighten($color-gray, 6%) !important;
  color: lighten($color-light, 20%);
  border: 0.5px solid $color-gray;
  border-left: none;
 }

 :deep(.tooltip-relative) {
  z-index: $z-index-xs;
 }
</style>